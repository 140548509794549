import React from "react";
import { useNavigate } from "react-router-dom";
import "./css/HomePage.css";

function HomePage() {
  const navigate = useNavigate();

  return (
    <div className="main-container">
    <div className="box_list">
        <div className="form_box">
        <h3>Survey</h3>
        <button className="btn"onClick={() => navigate("/form")}>
          Go to Form Page
        </button>
        </div>
    </div>
  </div>
  );
}

export default HomePage;