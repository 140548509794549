import React, { useState, useEffect } from "react";
import axios from "axios";
import "./css/FormPage.css";
import LoadingSpinner from "./LoadingSpinner";
import { delay } from "./utils";
import "@fortawesome/fontawesome-free/css/all.min.css";
import notification from './notification';

// Function to save form data to localStorage
const saveToLocalStorage = (formData) => {
  localStorage.setItem("formData", JSON.stringify(formData));
};

// Function to load form data from localStorage
const loadFromLocalStorage = () => {
  const savedData = localStorage.getItem("formData");
  return savedData ? JSON.parse(savedData) : {};
};

function FormPage() {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [gender, setGender] = useState("");
  const [ageGroup, setAgeGroup] = useState("");
  const [profession, setProfession] = useState("");
  const [otherProfession, setOtherProfession] = useState("");
  const [orgName, setOrgName] = useState("");
  const [sector, setSector] = useState("");
  const [otherSector, setOtherSector] = useState("");
  const [workBri, setWorkBri] = useState("");
  const [useAI, setUseAI] = useState("");
  const [aiTools, setAiTools] = useState([]);
  const [otherAiTools, setOtherAiTools] = useState([""]);
  const [benefits, setBenefits] = useState([]);
  const [otherBenefits, setOtherBenefits] = useState([""]);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  useEffect(() => {
    document.title = "Survey Form";
    // Load data from localStorage when component mounts
    const storedData = loadFromLocalStorage();
    if (storedData) {
      setName(storedData.name || "");
      setPhone(storedData.phone || "");
      setAddress(storedData.address || "");
      setEmail(storedData.email || "");
      setGender(storedData.gender || "");
      setAgeGroup(storedData.ageGroup || "");
      setProfession(storedData.profession || "");
      setOtherProfession(storedData.otherProfession || "");
      setOrgName(storedData.orgName || "");
      setSector(storedData.sector || "");
      setOtherSector(storedData.otherSector || "");
      setWorkBri(storedData.workBri || "");
      setUseAI(storedData.useAI || "");
      setAiTools(storedData.aiTools || []);
      setOtherAiTools(storedData.otherAiTools || [""]);
      setBenefits(storedData.benefits || []);
      setOtherBenefits(storedData.otherBenefits || [""]);
    }
  }, []);

  const handleBlur = (field, value) => {
    const storedData = loadFromLocalStorage();
    storedData[field] = value;
    saveToLocalStorage(storedData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsButtonLoading(true);
      await delay(3000);
      const response = await axios.post(
        "https://godavari.deshnomad.org/backend/submit_form.php",
        {
          name,
          phone,
          address,
          email,
          gender,
          ageGroup,
          profession,
          otherProfession,
          orgName,
          sector,
          otherSector,
          workBri,
          useAI,
          aiTools: [...aiTools, ...otherAiTools.filter((tool) => tool !== "")],
          benefits: [
            ...benefits,
            ...otherBenefits.filter((benefit) => benefit !== ""),
          ],
        }
      );

      if (response.data.status === "success") {
        setIsFormSubmitted(true);
        notification.success(response.data.message);
        localStorage.clear();
      } else {
        notification.error(response.data.message);
      }
    } catch (error) {
      notification.error("Error submitting form");
    } finally {
      setIsButtonLoading(false);
    }
  };

  const handleAiToolChange = (tool) => {
    setAiTools((prevTools) =>
      prevTools.includes(tool)
        ? prevTools.filter((t) => t !== tool)
        : [...prevTools, tool]
    );
  };

  const handleOtherAiToolChange = (index, value) => {
    const newOtherAiTools = [...otherAiTools];
    newOtherAiTools[index] = value;
    setOtherAiTools(newOtherAiTools);

    if (value !== "" && index === otherAiTools.length - 1) {
      setOtherAiTools([...otherAiTools, ""]);
    } else if (value === "" && otherAiTools.length > 1) {
      setOtherAiTools(newOtherAiTools.slice(0, -1));
    }
  };

  const handleBenefitChange = (benefit) => {
    setBenefits((prevBenefits) =>
      prevBenefits.includes(benefit)
        ? prevBenefits.filter((b) => b !== benefit)
        : [...prevBenefits, benefit]
    );
  };

  const handleOtherBenefitChange = (index, value) => {
    const newOtherBenefits = [...otherBenefits];
    newOtherBenefits[index] = value;
    setOtherBenefits(newOtherBenefits);

    if (value !== "" && index === otherBenefits.length - 1) {
      setOtherBenefits([...otherBenefits, ""]);
    } else if (value === "" && otherBenefits.length > 1) {
      setOtherBenefits(newOtherBenefits.slice(0, -1));
    }
  };

  const handleShare = () => {
    const shareData = {
      title: "Survey Form",
      text: "Hello! I’m Godavari Sharma, a first year student at GNLU conducting a survey on the usage of Generative Artificial Intelligence amongst today’s youth. Please do take a moment to fill out the below attached form, your participation will be greatly appreciated! ",
      url: "https://godavari.deshnomad.org/form",
    };

    if (navigator.share) {
      navigator
        .share(shareData)
        .then(() => console.log("Successful share"))
        .catch((error) => console.log("Error sharing", error));
    } else {
      alert(
        "Web Sharing is not supported in your browser. Please copy the link: https://godavari.deshnomad.org/form"
      );
    }
  };

  return (
    <div className="main-container">
      {isFormSubmitted ? (
        <div className="thank-you-container">
          <div className="thank-you-box">
            <img
              src="img/thanks.png"
              alt="Thank You Image"
              className="thank-you-image"
            />
            <h4>
              I really appreciate your time and effort in completing the
              survey.Thank You again By <b>Godavari Sharma</b>
            </h4>
            <button onClick={handleShare}>
              Share <i className="fas fa-share-alt"></i>
            </button>
          </div>
        </div>
      ) : (
        <div className="form-container">
          <h1>
            Survey on usage of Generative Artificial Intelligence by Youth
          </h1>
          <div className="form-header">
            <p>
              Greetings! <br />
              <br />I am <b> Godavari Sharma</b>, a first-year student at{" "}
              <b>Gujarat National Law University.</b>
              <br />
              <br /> I am conducting a questionnaire as part of an assignment to
              assess how today's youth are utilizing Generative Artificial
              Intelligence in various fields such as content creation, finance,
              and research & development. <br />
              <br />
              Your responses will be invaluable to my study, and I assure you
              that any information shared through this form will be kept
              strictly confidential.
              <br />
              <br /> Thank you for taking the time to contribute your valuable
              input!
            </p>
          </div>
          <h3>Kindly fill in the following details:</h3>
          <form onSubmit={handleSubmit}>
            <div>
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                onBlur={(e) => handleBlur("name", e.target.value)}
                placeholder="ex. : Godavari Sharma"
                required
              />
              <label>Name</label>
            </div>
            <div>
              <input
                type="number"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                onBlur={(e) => handleBlur("phone", e.target.value)}
                placeholder="ex. : 9876543210"
                required
              />
              <label>Phone Number</label>
            </div>
            <div>
              <input
                type="text"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                onBlur={(e) => handleBlur("address", e.target.value)}
                placeholder="ex. : Your Address, City, State, Country, PIN-CODE"
                required
              />
              <label>Full Address</label>
            </div>
            <div>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onBlur={(e) => handleBlur("email", e.target.value)}
                placeholder="ex. : godavari@example.com"
                required
              />
              <label>Email</label>
            </div>
            <div>
              <select
                value={gender}
                onChange={(e) => setGender(e.target.value)}
                onBlur={(e) => handleBlur("gender", e.target.value)}
                required
              >
                <option value="" disabled hidden>
                  Select Gender
                </option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="other">Other</option>
              </select>
              <label>Gender</label>
            </div>
            <div>
              <select
                value={ageGroup}
                onChange={(e) => setAgeGroup(e.target.value)}
                onBlur={(e) => handleBlur("ageGroup", e.target.value)}
                required
              >
                <option value="" disabled hidden>
                  Select Age Group
                </option>
                <option value="18-25">18-25 years</option>
                <option value="25-30">25-30 years</option>
                <option value="30-35">30-35 years</option>
              </select>
              <label>Age Group</label>
            </div>
            <div>
              <select
                value={profession}
                onChange={(e) => setProfession(e.target.value)}
                onBlur={(e) => handleBlur("profession", e.target.value)}
                required
              >
                <option value="" disabled hidden>
                  Select Profession
                </option>
                <option value="student">Student</option>
                <option value="working">Working Professional</option>
                <option value="other">Other</option>
              </select>
              <label>Profession</label>
            </div>
            {profession === "other" && (
              <div>
                <input
                  type="text"
                  value={otherProfession}
                  onChange={(e) => setOtherProfession(e.target.value)}
                  onBlur={(e) => handleBlur("otherProfession", e.target.value)}
                  placeholder="ex. : Freelancer"
                />
                <label className="additional-label">
                  Your Profession Name (optional)
                </label>
              </div>
            )}
            <div>
              <input
                type="text"
                value={orgName}
                onChange={(e) => setOrgName(e.target.value)}
                onBlur={(e) => handleBlur("orgName", e.target.value)}
                placeholder="ex. : XYZ University"
                required
              />
              <label>Name of school/college/organisation</label>
            </div>
            <div>
              <select
                value={sector}
                onChange={(e) => setSector(e.target.value)}
                onBlur={(e) => handleBlur("sector", e.target.value)}
                required
              >
                <option value="" disabled hidden>
                  Choose a sector
                </option>
                <option value="finance">Finance/Business</option>
                <option value="content">
                  Content Creation (Videographic, written, visual, etc.)
                </option>
                <option value="research">Research or Development</option>
                <option value="other">Other</option>
              </select>
              <label>Which sector do you primarily work with?</label>
            </div>
            {sector === "other" && (
              <div>
                <input
                  type="text"
                  value={otherSector}
                  onChange={(e) => setOtherSector(e.target.value)}
                  onBlur={(e) => handleBlur("otherSector", e.target.value)}
                  placeholder="ex. : Healthcare"
                />
                <label className="additional-label">
                  Write your sector name (optional)
                </label>
              </div>
            )}
            <div>
              <input
                type="text"
                value={workBri}
                onChange={(e) => setWorkBri(e.target.value)}
                onBlur={(e) => handleBlur("workBri", e.target.value)}
                placeholder="ex. : Developing educational content for students."
                required
              />
              <label>Briefly elaborate on your work.</label>
            </div>
            <div>
              <select
                value={useAI}
                onChange={(e) => setUseAI(e.target.value)}
                onBlur={(e) => handleBlur("useAI", e.target.value)}
                required
              >
                <option value="" disabled hidden>
                  Choose an option
                </option>
                <option value="yes">Yes, I do</option>
                <option value="no">No, I don't</option>
              </select>
              <label>
                Do you use Generative Artificial Intelligence to aid your work?
              </label>
            </div>
            {useAI === "yes" && (
              <div>
                <label>If yes, kindly list the tools you use</label>
                <div>
                  <div className="checkbox-group">
                    <input
                      type="checkbox"
                      value="Chat GPT"
                      checked={aiTools.includes("Chat GPT")}
                      onChange={() => handleAiToolChange("Chat GPT")}
                    />
                    <label>Chat GPT</label>
                  </div>
                  <div className="checkbox-group">
                    <input
                      type="checkbox"
                      value="QuillBot"
                      checked={aiTools.includes("QuillBot")}
                      onChange={() => handleAiToolChange("QuillBot")}
                    />
                    <label>QuillBot</label>
                  </div>
                  <div className="checkbox-group">
                    <input
                      type="checkbox"
                      value="Notion AI"
                      checked={aiTools.includes("Notion AI")}
                      onChange={() => handleAiToolChange("Notion AI")}
                    />
                    <label>Notion AI</label>
                  </div>
                  <div className="checkbox-group">
                    <input
                      type="checkbox"
                      value="Gemini"
                      checked={aiTools.includes("Gemini")}
                      onChange={() => handleAiToolChange("Gemini")}
                    />
                    <label>Gemini</label>
                  </div>
                </div>
                <div>
                  {otherAiTools.map((tool, index) => (
                    <div key={index} className="extra_ai_input">
                      <input
                        type="text"
                        value={tool}
                        onChange={(e) =>
                          handleOtherAiToolChange(index, e.target.value)
                        }
                        onBlur={(e) =>
                          handleBlur(`otherAiTool${index}`, e.target.value)
                        }
                        placeholder="Other AI tool (optional)"
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}
            {useAI === "yes" && (
              <div>
                <label>How is Generative AI beneficial in your work?</label>
                <div>
                  <div className="checkbox-group">
                    <input
                      type="checkbox"
                      value="Aids in organisation and time management"
                      checked={benefits.includes(
                        "Aids in organisation and time management"
                      )}
                      onChange={() =>
                        handleBenefitChange(
                          "Aids in organisation and time management"
                        )
                      }
                    />
                    <label>Aids in organisation and time management</label>
                  </div>
                  <div className="checkbox-group">
                    <input
                      type="checkbox"
                      value="Assimilation of data & statistics"
                      checked={benefits.includes(
                        "Assimilation of data & statistics"
                      )}
                      onChange={() =>
                        handleBenefitChange("Assimilation of data & statistics")
                      }
                    />
                    <label>Assimilation of data & statistics</label>
                  </div>
                  <div className="checkbox-group">
                    <input
                      type="checkbox"
                      value="Collects facts efficiently - helps with research"
                      checked={benefits.includes(
                        "Collects facts efficiently - helps with research"
                      )}
                      onChange={() =>
                        handleBenefitChange(
                          "Collects facts efficiently - helps with research"
                        )
                      }
                    />
                    <label>
                      Collects facts efficiently - helps with research
                    </label>
                  </div>
                  <div className="checkbox-group">
                    <input
                      type="checkbox"
                      value="Formulates original content ideas"
                      checked={benefits.includes(
                        "Formulates original content ideas"
                      )}
                      onChange={() =>
                        handleBenefitChange("Formulates original content ideas")
                      }
                    />
                    <label>Formulates original content ideas</label>
                  </div>
                </div>
                <div>
                  {otherBenefits.map((benefit, index) => (
                    <div key={index} className="extra_ai_input">
                      <input
                        type="text"
                        value={benefit}
                        onChange={(e) =>
                          handleOtherBenefitChange(index, e.target.value)
                        }
                        onBlur={(e) =>
                          handleBlur(`otherBenefit${index}`, e.target.value)
                        }
                        placeholder="Other benefit (optional)"
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}
            <div>
              <button type="submit">
                {isButtonLoading ? <LoadingSpinner /> : "Submit"}
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
}

export default FormPage;
